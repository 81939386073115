export const counting = {
  methods: {
    // 카운팅효과
    counting(element, num) {
      if (num == 0) {
        element.innerText = "0";
      } else {
        const each = Math.ceil(num / 33);
        let time = 0;
        for (let i = 0; i <= num; i += each) {
          setTimeout(() => {
            element.innerText = i;
          }, 20 * time);
          if (i + each > num) {
            setTimeout(() => {
              element.innerText = this.numberFormat(num);
            }, 20 * (time + 1));
          }
          time++;
        }
      }
    },
  },
}
