<template>
  <div class="screen_filter" v-if="this.$store.state.resignContent"></div>
  <transition name="popup">
    <div class="confirm" v-if="this.$store.state.resignContent">
      <div class="resign_message_container">
        <p>{{ this.$store.state.resignContent }}</p>
        <input
          class="resign_leave_message"
          type="text"
          placeholder="ex) 더 이상 사용하지 않습니다."
          v-model="this.$store.state.resignMessage"
        />
      </div>
      <div class="confirm_content">
        <button type="button" class="btn btn_continue" @click="resignContinue">
          확인
        </button>
        <button
          type="button"
          class="btn btn_close"
          id="confirm_close_btn"
          @click="resignCancel"
        >
          취소
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "resign",
  updated() {
    document.getElementById("confirm_close_btn").focus();
  },
  methods: {
    resignContinue() {
      this.$store.commit("resignContinue");
    },
    resignCancel() {
      this.$store.commit("resignCancel");
    },
  },
};
</script>

<style scoped>
.screen_filter {
  z-index: 1000;
}
.confirm {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  background-color: #fff;
  border-radius: var(--border-radius);
  z-index: 1000;
}
.confirm .resign_message_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  font-size: 2rem;
}
.confirm .resign_leave_message {
  margin-top: 1rem;
  height: 2rem;
  width: 70%;
  padding: 0.5rem;
  font-size: 1.6rem;
}
.confirm .confirm_content {
  height: calc(100% - 10rem);
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.8rem;
  word-break: keep-all;
}
.confirm .confirm_content .btn {
  border: none;
  font-size: 1.5rem;
  color: #fff;
  padding: 0.6rem 2.1rem;
  border-radius: var(--border-radius);
  margin-top: 1.5rem;
  cursor: pointer;
}
.confirm .confirm_content .btn_continue {
  background-color: var(--point-color);
  margin-right: 10px;
}
.confirm .confirm_content .btn_continue:hover {
  background-color: #7cadac;
}
.confirm .confirm_content .btn_close {
  background-color: var(--primary-color1);
}
.confirm .confirm_content .btn_close:hover {
  background-color: var(--primary-color2);
}

/* transition popup */
.popup-enter-from,
.popup-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
}
.popup-enter-active,
.popup-leave-active {
  transition: all 0.1s ease-in-out;
}
</style>
