<template>
  <div class="screen_filter" v-if="this.$store.state.alertContent"></div>
  <transition name="popup">
    <div class="alert" v-if="this.$store.state.alertContent">
      <div class="alert_content">
        <img src="@/assets/images/common/logo_01.svg" alt="로고" />
        <p v-html="this.$store.state.alertContent"></p>
        <button
          type="button"
          class="btn_close"
          id="alert_close_btn"
          @click="closeAlert"
        >
          확인
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Alert",
  updated() {
    document.getElementById("alert_close_btn").focus();
  },
  methods: {
    closeAlert() {
      this.$store.commit("closeAlert");
    },
  },
};
</script>

<style scoped>
.screen_filter {
  z-index: 1000;
}
.alert {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  background-color: #fff;
  border-radius: var(--border-radius);
  z-index: 1000;
}
.alert .alert_content {
  height: calc(100% - 10rem);
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem 2.5rem;
  text-align: center;
  font-size: var(--font-mid);
  word-break: keep-all;
}
.alert .alert_content>img {
  width: 11rem;
  margin: 0 auto 1rem;
}
.alert .alert_content p {
  border-top: 1px solid #ccc;
  padding-top: 1.5rem;
  font-weight: var(--font-w-mid);
}
.alert .alert_content p >>> .tiny {
  font-weight: var(--font-w-tiny);
}
.alert .alert_content .btn_close {
  border: none;
  font-size: 1.5rem;
  color: #fff;
  background-color: var(--primary-color1);
  padding: 0.6rem 2.1rem;
  border-radius: var(--border-radius);
  margin-top: 2.5rem;
  cursor: pointer;
}
.alert .alert_content .btn_close:hover {
  background-color: var(--primary-color2);
}

/* transition popup */
.popup-enter-from,
.popup-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
}
.popup-enter-active,
.popup-leave-active {
  transition: all 0.1s ease-in-out;
}
</style>