<template>
  <div class="screen_filter" v-if="this.$store.state.confirmContent"></div>
  <transition name="popup">
    <div class="confirm" v-if="this.$store.state.confirmContent">
      <div class="confirm_content">
        <img src="@/assets/images/common/logo_01.svg" alt="로고" />
        <p v-html="this.$store.state.confirmContent"></p>
        <button type="button" class="btn btn_continue" @click="confirmContinue">
          확인
        </button>
        <button
          type="button"
          class="btn btn_close"
          id="confirm_close_btn"
          @click="confirmCancel"
        >
          취소
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "confirm",
  updated() {
    document.getElementById("confirm_close_btn").focus();
  },
  methods: {
    confirmContinue() {
      this.$store.commit("confirmContinue");
    },
    confirmCancel() {
      this.$store.commit("confirmCancel");
    },
  },
};
</script>

<style scoped>
.screen_filter {
  z-index: 1000;
}
.confirm {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  background-color: #fff;
  border-radius: var(--border-radius);
  z-index: 1000;
}
.confirm .confirm_content {
  height: calc(100% - 10rem);
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem 2.5rem;
  text-align: center;
  font-size: var(--font-mid);
  word-break: keep-all;
}
.confirm .confirm_content>img {
  width: 11rem;
  margin: 0 auto 1rem;
}
.confirm .confirm_content p {
  border-top: 1px solid #ccc;
  padding-top: 1.5rem;
  font-weight: var(--font-w-mid);
}
.confirm .confirm_content .btn {
  border: none;
  font-size: 1.5rem;
  color: #fff;
  padding: 0.6rem 2.1rem;
  border-radius: var(--border-radius);
  margin-top: 2.5rem;
  cursor: pointer;
}
.confirm .confirm_content .btn_continue {
  background-color: var(--point-color);
  margin-right: 1rem;
}
.confirm .confirm_content .btn_continue:hover {
  background-color: var(--point-color-hover);
}
.confirm .confirm_content .btn_close {
  background-color: var(--primary-color1);
}
.confirm .confirm_content .btn_close:hover {
  background-color: var(--primary-color2);
}

/* transition popup */
.popup-enter-from,
.popup-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
}
.popup-enter-active,
.popup-leave-active {
  transition: all 0.1s ease-in-out;
}
</style>