export default {
  namespaced: true,
  state: {
    popup: {
      showPopup: false,
      whichPopup : '',
      data : {},
    },
  },
  mutations: {
    openPopup(state, payload) {
      state.popup.showPopup = true;
      state.popup.data = payload.data;
      state.popup.whichPopup = payload.whichPopup;
    },
    closePopup(state) {
      state.popup.showPopup = false;
      state.popup.whichPopup = '';
      state.popup.data = {};
    },
  }
}