import store from '../store'

export const fetchData = {
    methods: {
        // 해당 url로 데이터 요청 후 응답오면 콜백 실행
        fetchData(url, callback = () => {}, params = {}) {
            if(url !== '/verifyNick') store.commit('startLoading'); // 로딩 시작
            this.axios.get(url, {
                params: params,
                headers:{
                    authorization : store.state.access_token.access_token,
                }
            })
            .then(res => {
                // console.log(res.data);
                if (res.data.success) {
                    callback(res.data);
                } else{
                    if(url === '/verifyNick'){
                        callback(res.data);
                    }else{
                        store.commit('alert', '데이터를 받아오는것에 실패했습니다.');
                    }
                }
                store.commit('finishLoading'); // 로딩 완료
            }).catch(error => {
                console.error(error);
                store.commit('finishLoading'); // 로딩 완료
            });
        },

    },
}