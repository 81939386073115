<template>
  <div class="screen_filter" v-if="this.$store.state.signupPopContent"></div>
  <transition name="popup">
    <div class="signupPop" v-if="this.$store.state.signupPopContent">
      <div class="signupPop_content">
        <div class="img_wrapper">
          <img src="@/assets/images/common/icon_check_w.svg" alt="check">
        </div>
        <p v-html="this.$store.state.signupPopContent"></p>
        <router-link class="point" to="/register/partner" @click="closeSignupPop">파트너 가입하기 <span class="gt">></span></router-link>
        <br/>
        <a @click="closeSignupPop">skip <span class="gt">></span></a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SignupPop",
  methods: {
    closeSignupPop() {
      this.$store.commit("closeSignupPop");
    },
  },
};
</script>

<style scoped>
.screen_filter {
  z-index: 1000;
}
.signupPop {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  background-color: #fff;
  border-radius: var(--border-radius);
  z-index: 1000;
}
.signupPop .signupPop_content {
  height: calc(100% - 10rem);
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.8rem;
  word-break: keep-all;
}
.signupPop .signupPop_content .img_wrapper {
  width: 1.7rem;
  height: 1.7rem;
  background: rgb(0, 167, 19);
  margin: 0 auto;
  padding: 0.6rem;
  border-radius: 50%;
  margin-bottom: 1.5rem;
}
.signupPop .signupPop_content p {
  font-weight: var(--font-w-mid);
  margin-bottom: 1.5rem;
}
.signupPop .signupPop_content p >>> .tiny {
  font-weight: var(--font-w-tiny);
}
.signupPop .signupPop_content>a {
  border: none;
  font-size: 1.6rem;
  color: var(--font-color-place);
  cursor: pointer;
}
.signupPop .signupPop_content>a .gt{
  font-size: 1.2rem;
  font-weight: var(--font-w-bold);
  vertical-align: middle;
}
.signupPop .signupPop_content>a.point {
  color: var(--point-color);
  font-weight: var(--font-w-mid);
}
.signupPop .signupPop_content>a:hover {
  color: #222;
}
.signupPop .signupPop_content>a.point:hover {
  color: var(--point-color-hover);
}

/* transition popup */
.popup-enter-from,
.popup-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
}
.popup-enter-active,
.popup-leave-active {
  transition: all 0.1s ease-in-out;
}
</style>