import router from '../../router'
import store from '../../store'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    user: {
      email: '',
      user_division: '',
      access_token: '',
      meta_token: 0,
    },
  },
  mutations: {
    signin(state, payload) {
      if (payload.access_token) {
        store.state.access_token.access_token = payload.access_token;
        store.state.access_token.refresh_token = payload.refresh_token;
        state.user = payload;
        router.push('/');
      } else {
        store.commit('alert', '로그인에 실패했습니다.');
      }
    },
    logout(state) {
      if(store.state.access_token.access_token){
        axios.post("/logout", {}, { headers: {
          authorization : store.state.access_token.access_token,
        }})
        .then((res) => {
          store.commit("alert", res.data.message);
        })
        .catch((error) => {
          console.error(error);
        });
      }

      state.user = {};
      store.state.access_token.access_token = null;
      store.state.access_token.refresh_token = null;
      router.push('/');
    },
    // 메타토큰 사용시 업데이트
    metaTokenUse(state, payload) {
      state.user.meta_token = state.user.meta_token - payload;
    },
    // 메타토큰 구매시 업데이트
    metaTokenPlus(state, payload) {
      state.user.meta_token = state.user.meta_token + payload;
    },
    myPageLogout(state){
      store.commit('alert', '회원정보가 수정되었습니다. 다시 로그인해주세요.');
      state.user = {};
      store.state.access_token.access_token = null;
      router.push('/signin')
    }
  }
}