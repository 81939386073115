import store from '../store'

export const postData = {
  methods: {
    // 해당 url로 데이터 보낸 후 응답오면 콜백 실행
    postData(url, payload, callback = () => {}, header = {}) {
      if(url!=='/saveUserState') store.commit('startLoading'); // 로딩 시작

      this.axios.post(url, payload, {
        headers: {
          ...header,
          authorization : store.state.access_token.access_token,
        }
      })
      .then((res) => {
        // console.log(res.data);
        store.commit('finishLoading'); // 로딩 완료
        if (res.data.success) {
          if (res.data) {
            callback(res.data);
          } else {
            callback();
          }
        } else {
          store.commit('alert', res.data.message);
        }
      }).catch(error => {
        console.error(error);
        store.commit('finishLoading'); // 로딩 완료
      });
    }
  },
}