import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import user from "./modules/user";
import access_token from "./modules/access_token";
import ai from "./modules/ai";

import axios from "axios";
import router from "@/router";

export default createStore({
    state: {
        alertContent: "", // 알림창 내용
        confirmContent: "", // 질문창 내용
        confirmCallback: null,

        resignContent: "",
        resignCallback: null,
        resignMessage: "",
        signupPopContent: "", // 회원가입 알림창 내용


        loading: false, // 로딩

        checkPass: false, // 마이페이지 유저정보 관리 들어가기 전 체크

        isUpdate: false,
        matchingData: {},
        productData: {},
    },
    mutations: {
        // 알림창
        alert(state, payload) {
            state.alertContent = payload;
            this.alertTimeout = setTimeout(() => {
                state.alertContent = "";
            }, 3000);
        },
        closeAlert(state) {
            state.alertContent = "";
            clearTimeout(this.alertTimeout);
        },

        // 질문(confirm)창
        confirm(state, payload) {
            state.confirmContent = payload.content;
            state.confirmCallback = payload.callback;
        },
        confirmContinue(state) {
            state.confirmContent = "";
            state.confirmCallback();
        },
        confirmCancel(state) {
            state.confirmContent = "";
            state.confirmCallback = null;
        },

        // 회원가입 알림창
        signupPop(state) {
            state.signupPopContent = '회원가입이 완료되었습니다';
        },
        closeSignupPop(state){
            state.signupPopContent = "";
        },

        // 회원탈퇴 알림창
        resign(state, payload) {
            state.resignContent = payload.content;
            state.resignCallback = payload.callback;
        },
        resignContinue(state) {
            state.resignContent = "";
            state.resignCallback();
        },
        resignCancel(state) {
            state.resignContent = "";
            state.confirmCallback = null;
        },
        clearResignMessage(state) {
            state.resignMessage = "";
        },

        // 로딩
        startLoading(state) {
            state.loading = true;
        },
        finishLoading(state) {
            state.loading = false;
        },

        // checkPass
        setCheckPass(state, value) {
            state.checkPass = value;
        },
        //is matching update
        setUpdate(state, value) {
            state.isUpdate = value;
        },
        setMatchingData(state, payload) {
            state.matchingData = payload;
        },
        setProductData(state, payload) {
            state.productData = payload;
        },
    },
    actions: {
        UPDATE_PRODUCT: (context, pk) => {
            const params = {
                access_token: context.state.access_token.access_token,
                product_pk: pk,
            };
            context.commit("startLoading");
            axios.get("/getProductInfo", { 
                params: params,
                headers:{
                    authorization : context.state.access_token.access_token,
                }
            })
            .then((res) => {
                if (res.data.success) {
                    context.commit("setProductData", res.data.data);
                    router.push(`/update/provider/${pk}`);
                } else {
                    context.commit("alert", "데이터를 받아오는것에 실패했습니다.");
                }
                context.commit("finishLoading");
            })
            .catch((error) => {
                console.error(error);
                context.commit("finishLoading");
            });
            
        },
        UPDATE_MATCHING: (context, { pk, state }) => {
            const params = {
                access_token: context.state.access_token.access_token,
                matching_pk: pk,
            };
            context.commit("startLoading");
            axios.get("/getMatchingInfo", {
                params: params,
                headers:{
                    authorization : context.state.access_token.access_token,
                }
            })
            .then((res) => {
                if (res.data.success) {
                    context.commit("setMatchingData", {
                        ...res.data.data,
                        url: res.data.data.url === "null" ? "" : res.data.data.url,
                        state: state,
                    });
                    router.push(`/update/seller/${pk}`);
                } else {
                    context.commit("alert", "데이터를 받아오는것에 실패했습니다.");
                }
                context.commit("finishLoading");
            })
            .catch((error) => {
                console.error(error);
                context.commit("finishLoading");
            });
        },
    },
    modules: {
        user,
        access_token,
        ai,
    },
    plugins: [
        createPersistedState({
            // storage: window.sessionStorage,
            paths: ["access_token"],
        }),
    ],
});