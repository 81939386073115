<template>
	<div class="screen_filter" v-if="this.popupOpen"></div>
	<div class="content_container popup" v-if="this.popupOpen">
		<div class="btn_wrap">
			<label>
				<input type="checkbox" v-model="todayCheck" style="background-color:#fff"/>
				오늘 하루 보지 않기
			</label>
			<button type="button">
				<img 
					src="@/assets/images/common/icon_close_w.svg"
					alt="닫기 버튼"
					@click="closePopup"
				/>
			</button>
		</div>
		<!--inner 시작 -->
		<div class="inner">
			<swiper v-bind="swiperOption">
				<swiper-slide v-for="(item, i) in this.popupData" :key="i">
					<img :src="item.path + item.save_name" :alt="item.original_name">
					<a :href="item.link">
						<button v-if="item.button" class="common_button">
								{{item.button}}
						</button>
					</a>
				</swiper-slide>
			</swiper>
		</div>
		<!-- <div class="inner">e
			<div class="top_title">
					<img 
						src="@/assets/images/main/illu_popup.jpg" 
						class="illu" alt="팝업 일러스트"
					/>
					<img 
						class="logo" 
						src="@/assets/images/common/logo_01.svg" 
						alt="로고"
					/>
					<h1>각 분야 판매자, 공급자를 모십니다.</h1>
			</div>

			<div class="benefit_wrap">
				<h2>
					메타바이셀은 회원 가입 후, 입점 지원을 하시는 회원님께 
					선착순대로 아래와 같은 혜택을 드립니다.
				</h2>
				<div class="price_wrap">
					<h1>토큰 (바이셀 토큰) 지급</h1>
					<div class="price">
						<h6 class="first"><span>1</span>위</h6>
						<div>
							<img src="@/assets/images/main/illu_token.svg" alt="거래토큰"/>
							<p>10개 <span>(100,000원)</span></p>
						</div>
					</div>
					<div class="price">
						<h6 class="second"><span>2</span>위</h6>
						<div>
							<img src="@/assets/images/main/illu_token.svg" alt="거래토큰"/>
							<p>5개 <span>(50,000원)</span></p>
						</div>
					</div>
					<div class="price">
						<h6 class="third"><span>3</span>위</h6>
						<div>
							<img src="@/assets/images/main/illu_token.svg" alt="거래토큰"/>
							<p>3개 <span>(30,000원)</span></p>
						</div>
					</div>
				</div>
				<p>
						바이셀 토큰은 메타바이셀 거래 플랫폼 안에서 거래할 수 있는 토큰으로 현금화가 가능합니다. <br/>
						단, 입점 가입 후, 1개월이 경과되어야 현금화를 할 수 있으며 탈퇴 시에는 지급하지 않습니다. <br/>
						또한, 메타바이셀 안에서 처음 거래를 진행하시면 추가로 20개의 토큰(200,000원)을 지급해 드립니다.
				</p>
			</div>
			
			<div class="bottom_text">
				<h1>지원 방법</h1>
				<div class="method_wrap">
					<div class="method">
						<p>1. 회원 가입</p>
					</div>
					<div class="line"></div>
					<div class="method">
						<p>2. 파트너 등록</p>
					</div>
					<div class="line"></div>
					<div class="method">
						<p>3. My Page</p>
					</div>
					<div class="line"></div>
					<div class="method">
						<p>4. 입점확인 클릭</p>
					</div>
				</div>
			</div>

			<button 
				type="button" 
				class="common_button"
				@click="goSignup"
			>
				회원 가입 하러 가기
			</button>
		</div> -->
		<!--inner 끝-->

		<!--inner2 시작-->
		<!-- <div class="inner inner2" style="display:none">
			<div class="top_title">
					<img src="@/assets/images/main/illu_popup.jpg" class="illu" alt="팝업 일러스트" />
				<img class="logo" src="@/assets/images/common/logo_01.svg" alt="로고" />
				<h1><span>회원가입</span> 하셨나요?</h1>
			</div>

			<div class="text_wrap">
				<div class="icon_wrap">
					<img src="@/assets/images/common/icon_survey.svg"/>
				</div>
				<h2>
					간단한 설문조사에 응해주세요.<br/>
					응답하신 모든 분들께 <span>바이셀 토큰 1개 (10,000원)</span>을 지급해 드립니다.
				</h2>

				<p>
					회원님의 생각이 서비스 질과 향상과 의미있는 정보교류에 많은 도움이 됩니다.<br/>
					My page의 트렝디언서 서베이 메뉴에서도 하실 수 있습니다.
				</p>
			</div>

			<button type="button" class="common_button" >
				<a href="https://docs.google.com/forms/d/1t1TcdK5W3ND4sEJynI0ZbFk1M5hYRhvSzZgHm97fbPc/viewform?edit_requested=true">
					설문조사 가기
				</a>
			</button>
		</div> -->
		<!--inner2 끝-->
	</div>
</template>

<script>
import { fetchData } from '@/utils/fetchData';
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([ Pagination, Autoplay ]);

export default {
	components:{
		Swiper,
		SwiperSlide,
	},
	mixins : [ fetchData ],
	data(){
		return{
			popupOpen: true,
			todayCheck: false,
			popupData: [],
			
			swiperOption: {
				// speed: 600,
				pagination: {
					clickable: true,
				},
				autoplay: {
					delay: 3000,
					disableOnInteraction: false,
				},
			},
		}
	},
	created(){
		if(this.getCloseForToday()){
			this.popupOpen = false;
		} else {
			this.fetchPopupData();
		}
	},
	methods:{
		fetchPopupData(){
			this.fetchData('/getMainPopup', (data)=>{
				this.popupData = data.popup;
			})
		},
		closePopup(){
			this.popupOpen = false;
			if(this.todayCheck){
				this.setCloseForToday();
			}
		},
		goSignup(){
			this.closePopup();
			this.$router.push('/signup');
		},
		setCloseForToday() {
			// 하루로 세팅
			const exdate = new Date();
			exdate.setDate(exdate.getDate() + 1);

			const cookie_value = escape(true) + '; expires=' + exdate.toUTCString();
			document.cookie = 'closeForToday' + '=' + cookie_value;
		},
		getCloseForToday() {
			let x, y;
			let val = document.cookie.split(';');

			for (let i = 0; i < val.length; i++) {
				x = val[i].substr(0, val[i].indexOf('='));
				y = val[i].substr(val[i].indexOf('=') + 1);
				x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
				if (x == 'closeForToday') {
					return unescape(y); // unescape로 디코딩 후 값 리턴
				}
			}
		}
	}
}
</script>

<style scoped src="@/assets/css/components/mainPopup.css">
</style>