<template>
  <div id="toTop" class="toTop" @click="toTop">
    <!-- <svg
      class="arrowUp"
      xmlns="http://www.w3.org/2000/svg"
      width="17.166"
      height="22.107"
      viewBox="0 0 17.166 22.107"
      style="fill: none; stroke: #fff; stroke-width: 2px"
    >
      <g transform="translate(0.707 22.107) rotate(-90)">
        <path class="a" d="M0,0H19.434" transform="translate(0 7.789)" />
        <path
          class="a"
          d="M0,15.752,7.876,7.876,0,0"
          transform="translate(12.816 0)"
        />
      </g>
    </svg> -->
    <img src="@/assets/images/common/icon_gotop.svg"/>
  </div>
</template>

<script>
export default {
  name: "ToTop",
  mounted() {
    window.addEventListener("scroll", this.toTopScrolled);
  },
  unmounted() {
    window.removeEventListener("scroll", this.toTopScrolled);
  },
  methods: {
    toTopScrolled() {
      const toTopEl = document.getElementById("toTop");
      if (window.scrollY > 50) {
        toTopEl.classList.add("scrolled");
      } else {
        toTopEl.classList.remove("scrolled");
      }
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
/* toTop 시작 */
.toTop {
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #fff; */
  opacity: 0;
  border-radius: 50%;
  transition: 0.6s;
  /*mix-blend-mode: difference;*/ /* 색 반전효과. body에 배경색 #fff를 넣지 않으면 안보일 수도 있음 */
  z-index: 10;
  visibility: hidden;
  display:flex;
  align-items: center;
  justify-content: center;
}
.toTop .arrowUp {
  width: 100%;
  /* height: 80%; */
}

/* 스크롤시 START */
.toTop.scrolled {
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}
</style>