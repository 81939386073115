<template>
  <div id="loading" v-if="this.$store.state.loading"></div>
</template>

<style>
@keyframes spining {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#loading {
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
#loading::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 5rem);
  left: calc(50% - 2rem);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.7rem solid var(--base-color1);
  border-top-color: var(--point-color);
  animation: spining 0.7s ease-in-out infinite;
}
</style>