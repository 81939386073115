<template>
  <MainPopup />

  <div class="content_container main">
    <!--page01 시작-->
    <div class="page01">
        <swiper v-bind="swiperOption">
            <swiper-slide v-for="(item) in this.ad_top" :key="JSON.stringify(item)">
                <div class="slide_big">
                    <img :src="item.bg_path+item.bg_save_name" :alt="item.original_name">
                </div>
                <span class="screen_dark"></span>
            </swiper-slide>
        </swiper>
        <div class="inner">
            <div class="text">
                <p>판매자-공급자 간<br/> 가장 스마트한 거래매칭 플랫폼</p>
                <img src="@/assets/images/common/logo_01_w.svg" alt="logo" />
                <div class="btn_wrap">
                    <button
                    type="button"
                    class="deal common_button"
                    @click="() => {
                            if(this.user.user_division == '공급자'){
                                this.$router.push('/matchingsuggest');
                            } else {
                                this.$router.push('/product');
                            }
                        }"
                    >
                        거래 제안하기
                    </button>

                    <button
                    type="button"
                    class="check sub_button"
                    @click="this.$router.push('/mypage')"
                    >
                        나의 요청내역
                    </button>
                </div>
            </div>

            <div class="slide_small_wrap">
                <swiper v-bind="swiperOption">
                    <swiper-slide v-for="(item) in this.ad_top" :key="JSON.stringify(item)">
                        <div class="slide_small">
                            <div class="image_wrap">
                                <span class="dark"></span>
                                <img :class="item.division_title == '판매자' ? 'user_seller' : 'user_provider'" :src="item.path+item.save_name" :alt="item.original_name" />
                            </div>
                            <div v-if="item.division_title == '판매자'" class="user">
                                <h1>{{item.name}}</h1>
                                <p><span class="label_seller">판매자</span>{{item.name}}</p>
                                <h2>카테고리 : <span>{{item.category[0].category + (item.category.length>1 ? ` 외${item.category.length - 1}` : '') }}</span></h2>
                                <button @click="this.$router.push(`/seller/detail/${item.pk}`)" class="sub_button">바로가기</button>
                            </div>
                            <div v-else class="user">
                                <h1>{{item.product_name}}</h1>
                                <p><span class="label_provider">공급자</span>{{item.name}}</p>
                                <h2>카테고리 : <span>{{item.category}}</span></h2>
                                <button @click="this.$router.push(`/product/detail/${item.pk}`)" class="sub_button">바로가기</button>
                            </div>
                        </div>
                    </swiper-slide>
                    <!-- <swiper-slide>
                        <div class="slide_small">
                            <div class="image_wrap">
                                <span class="dark"></span>
                                <img src="@/assets/images/main/main_mobile_01.jpg" alt="" />
                            </div>
                            <div class="user">
                                <h1>활동 TOP</h1>
                                <p><span class="label_seller">판매자</span>My Life</p>
                                <h2>전문 카테고리 : <span>뷰티 &amp; 패션</span></h2>
                                <button type="button" class="sub_button">바로가기</button>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_small">
                            <div class="image_wrap">
                                <span class="dark"></span>
                                <img src="@/assets/images/main/main_mobile_02.jpg" alt=""/>
                            </div>
                            <div class="user">
                                <h1>요즘 Trend</h1>
                                <p><span class="label_seller">판매자</span>Green House</p>
                                <h2>전문 카테고리 : <span>가구/인테리어</span></h2>
                                <button type="button" class="sub_button">바로가기</button>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_small">
                            <div class="image_wrap">
                                <span class="dark"></span>
                                <img src="@/assets/images/main/main_mobile_03.jpg" alt=""/>
                            </div>
                            <div class="user">
                            <h1>신규제조기업</h1>
                                <p><span class="label_seller">판매자</span>스마트 공장</p>
                                <h2>전문 카테고리 : <span>화장품 제조</span></h2>
                                <button type="button" class="sub_button">바로가기</button>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_small">
                            <div class="image_wrap">
                                <span class="dark"></span>
                                <img src="@/assets/images/main/main_mobile_04.jpg" alt=""/>
                            </div>
                            <div class="user">
                                <h1>신규 Supporters</h1>
                                <p><span class="label_seller">판매자</span>유통가이더</p>
                                <h2>전문 카테고리 : <span>마케팅/홍보/시장분석</span></h2>
                                <button type="button" class="sub_button">바로가기</button>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_small">
                            <div class="image_wrap">
                                <span class="dark"></span>
                                <img src="@/assets/images/main/main_mobile_05.jpg" alt="" />
                            </div>
                            <div class="user">
                                <h1>Metaverse 거래환경 실현</h1>
                                <p><span class="label_seller">판매자</span>My Life</p>
                                <h2>전문 카테고리 : <span>뷰티 &amp; 패션</span></h2>
                                <button type="button" class="sub_button">바로가기</button>
                            </div>
                        </div>
                    </swiper-slide> -->
                </swiper>
          
            </div>
        </div>
    </div>
    <!--page01 끝-->

    <!--page02 시작-->
    <div class="page02 page">
        <h1>
            <span>판매자, 공급자, 제조자의<br/> 시너지를 최대로 ...</span><br />
            <p>
                이제 무료 회원가입으로<br />
                전 세계 거래자들을 만나보세요 .
            </p>
        </h1>

        <div class="text_link">
            <router-link to="/signup">
                <p>회원가입</p>
                <span class="gt">></span>
            </router-link>
        </div>
        <div class="box_banner">
            <div class="box">
                <!-- 판매자 슬라이드 시작 -->
                <swiper v-if="this.ad_mid_seller.length <= 1" v-bind="swiperOptionDefault">
                    <swiper-slide v-if="ad_mid_seller.length == 0">
                        <div class="banner">
                            <div class="img_holder"></div>
                            <div class="text default">
                                판매자 광고가 없습니다.
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide v-else>
                        <div class="banner banner_seller">
                            <div v-if="ad_mid_seller[0].path" class="img_holder" :style="{ backgroundImage : `url(${ad_mid_seller[0].path+ad_mid_seller[0].save_name})` }"></div>
                            <div class="text">
                                <p>
                                <span>{{ad_mid_seller[0].name}}</span><br />
                                판매자 : {{ad_mid_seller[0].name}}<br />
                                </p>
                            </div>
                            <span>
                                <router-link :to="`/seller/detail/${ad_mid_seller[0].pk}`"
                                >판매자 보기 <span class="gt">></span></router-link
                                >
                            </span>
                        </div>
                    </swiper-slide>
                </swiper>
                <swiper v-else v-bind="swiperOption">
                    <swiper-slide v-for="(item) in this.ad_mid_seller" :key="JSON.stringify(item)">
                        <div class="banner banner_seller">
                            <div v-if="item.path" class="img_holder" :style="{ backgroundImage : `url(${item.path+item.save_name})` }"></div>
                            <div class="text">
                                <p>
                                <span>{{item.name}}</span><br />
                                판매자 : {{item.name}}<br />
                                </p>
                            </div>
                            <span>
                                <router-link :to="`/seller/detail/${item.pk}`"
                                >판매자 보기 <span class="gt">></span></router-link
                                >
                            </span>
                        </div>
                    </swiper-slide>
                </swiper>
                <!-- 판매자 슬라이드 끝 -->
				<p><router-link to="/seller">전체 판매자 보기</router-link></p>
            </div>
            
            <div class="box">
				<!-- 공급자/상품 슬라이드 시작 -->
				<swiper v-if="this.ad_mid_provider.length <= 1" v-bind="swiperOptionDefault">
					<swiper-slide v-if="ad_mid_provider.length == 0">
						<div class="banner">
							<div class="img_holder"></div>
							<div class="text default">
								상품 광고가 없습니다.
							</div>
						</div>
					</swiper-slide>
					<swiper-slide v-else>
						<div class="banner">
							<div class="img_holder" :style="{ backgroundImage : `url(${ad_mid_provider[0].path+ad_mid_provider[0].save_name})`}"></div>
							<div class="text">
								<p>
								<span>{{ad_mid_provider[0].product_name}}</span><br />
								공급자 : {{ad_mid_provider[0].name}}<br />
								</p>
							</div>
							<span>
								<router-link :to="`/product/detail/${ad_mid_provider[0].pk}`"
								>공급자 / 상품 보기 <span class="gt">></span></router-link
								>
							</span>
						</div>
					</swiper-slide>
				</swiper>
				<swiper v-else v-bind="swiperOption">
					<swiper-slide v-for="(item) in this.ad_mid_provider" :key="JSON.stringify(item)">
						<div class="banner">
							<div class="img_holder" :style="{ backgroundImage : `url(${item.path+item.save_name})`}"></div>
							<div class="text">
								<p>
								<span>{{item.product_name}}</span><br />
								공급자 : {{item.name}}<br />
								</p>
							</div>
							<span>
								<router-link :to="`/product/detail/${item.pk}`"
								>공급자 / 상품 보기 <span class="gt">></span></router-link
								>
							</span>
						</div>
					</swiper-slide>
				</swiper>
				<!-- 공급자/상품 슬라이드 끝 -->
				<p><router-link to="/provider">전체 공급자 보기</router-link></p>
			</div>
            
        </div>
    </div>
    <!--page02 끝-->

    <!--page03 시작-->
    <div class="page03 page">
        <h1>
            당신이 원하는
            <span>"소비재 제품"</span> 카테고리를 선택하세요 .<br />
            <p>
                인간 생활에 가장 밀접한 상품을 대상으로 판매자와 공급자 간 합리적인
                거래를 추진합니다.
            </p>
        </h1>
        <div class="box_category">
            <div class="category on" @click="categoryCheck($event, 0)">
                <p>{{ this.category[0].title }}</p>
                <img
                    src="@/assets/images/main/icon_category_02.svg"
                    alt="{{ this.category[0].title }}"
                />
                <img
                    src="@/assets/images/main/icon_category_02_w.svg"
                    alt="{{ this.category[0].title }}"
                />
            </div>
            <div class="category" @click="categoryCheck($event, 1)">
                <p>{{ this.category[1].title }}</p>
                <img
                    src="@/assets/images/main/icon_category_01.svg"
                    alt="{{ this.category[1].title }}"
                />
                <img
                    src="@/assets/images/main/icon_category_01_w.svg"
                    alt="{{ this.category[1].title }}"
                />
            </div>
            <div class="category" @click="categoryCheck($event, 2)">
                <p>{{ this.category[2].title }}</p>
                <img
                    src="@/assets/images/main/icon_category_03.svg"
                    alt="{{ this.category[2].title }}"
                />
                <img
                    src="@/assets/images/main/icon_category_03_w.svg"
                    alt="{{ this.category[2].title }}"
                />
            </div>
            <div class="category" @click="categoryCheck($event, 3)">
                <p>{{ this.category[3].title }}</p>
                <img
                    src="@/assets/images/main/icon_category_04.svg"
                    alt="{{ this.category[3].title }}"
                />
                <img
                    src="@/assets/images/main/icon_category_04_w.svg"
                    alt="{{ this.category[3].title }}"
                />
            </div>
            <div class="category" @click="categoryCheck($event, 4)">
                <p>{{ this.category[4].title }}</p>
                <img
                    src="@/assets/images/main/icon_category_05.svg"
                    alt="{{ this.category[4].title }}"
                />
                <img
                    src="@/assets/images/main/icon_category_05_w.svg"
                    alt="{{ this.category[4].title }}"
                />
            </div>
            <div class="category" @click="categoryCheck($event, 5)">
                <p>{{ this.category[5].title }}</p>
                <img
                    src="@/assets/images/main/icon_category_06.svg"
                    alt="{{ this.category[5].title }}"
                />
                <img
                    src="@/assets/images/main/icon_category_06_w.svg"
                    alt="{{ this.category[5].title }}"
                />
            </div>
        </div>

        <div class="page03_2">
            <h1>
                <span>"{{ this.category[this.whichCategory].title }}"</span> 의 거래
                규모입니다.
            </h1>
            <div class="box_scale">
                <div class="scale">
                    <div class="inner">
                        <h6>판매자 파트너</h6>
                        <img src="@/assets/images/common/icon_seller.svg" alt="수요자" />
                        <div class="text">
                            <h2><strong id="consumer-count">0</strong><span>명</span></h2>
                            <h3 id="consumer-total">{{this.category[this.whichCategory].consumer.total}}</h3>
                        </div>
                        <router-link to="/seller">
                            <i class="fas fa-search"></i>
                            <p>판매자 보기</p>
                        </router-link>
                    </div>
                </div>

                <div class="scale">
                    <div class="inner">
                        <h6>공급자 파트너</h6>
                        <img
                            src="@/assets/images/common/icon_provider.svg"
                            alt="공급자"
                        />
                        <div class="text">
                            <h2><strong id="provider-count">0</strong><span>명</span></h2>
                            <h3 id="provider-total">{{this.category[this.whichCategory].provider.total}}</h3>
                        </div>
                        <router-link to="/provider">
                            <i class="fas fa-search"></i>
                            <p>공급자 보기</p>
                        </router-link>
                    </div>
                </div>

                <div class="scale">
                    <div class="inner">
                        <h6>거래건수</h6>
                        <img
                            src="@/assets/images/common/icon_exchange.svg"
                            alt="거래건수"
                        />
                        <div class="text">
                            <h2><strong id="trade-count">0</strong><span>건</span></h2>
                        </div>
                        <router-link to="/product">
                            <i class="fas fa-search"></i>
                            <p>상품 보기</p>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="text_link">
                <router-link to="/seller">
                    <p>적당한 업체를 못 찾으셨나요? 직접 제안해주세요.</p>
                    <span class="gt">></span>
                </router-link>
            </div>
        </div>

        <router-link
            :to="{ name: 'MarketAnalyze', params: { which: this.whichCategory } }"
            class="btn_trendienser"
        >
            <button type="button" class="common_button">
                <span>"{{ this.category[this.whichCategory].title }}"</span> 시장분석
                보기
            </button>
            <!-- <img src="@/assets/images/common/logo_02.svg" alt="시장분석 로고"/> -->
        </router-link>
    </div>
    <!--page03 끝-->

    <!--광고 banner 시작-->
    <!-- <div class="banner_ad">
        <swiper v-bind="swiperOption">
            <swiper-slide v-for="(item, i) in this.ad_mid" :key="i">
                <div class="screen_dark" :style="{ backgroundImage: `url(${item.path+item.save_name})`}">
                    <div class="inner">
                        <h1>{{item.product_name}}</h1>
                        <p><span class="label_provider">공급자</span>{{item.name}}</p>
                        <h2>카테고리 : <span>{{item.category}}</span></h2>
                        <button @click="this.$router.push(`/product/detail/${item.pk}`)" class="common_button">바로가기</button>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div> -->
    <!--광고 banner 끝-->

    <!--page04 시작-->
    <div class="page04 page">
        <h1>
            <div class="title">
                <img src="@/assets/images/main/icon_ai.svg" alt="ai_icon">
                <strong><span>AI</span> 거래 매칭</strong>
            </div>
            <p>
                판매자에게 <span>가장 잘 맞는 거래</span>를 대용량 데이터의 알고리즘을
                통해 자동매칭-추천해드립니다.
            </p>
        </h1>
        <div class="box_graph">
            <div class="graph">
                <h2>
                    <span>"㈜*** 회원(공급자)"</span> 과 매칭 수준은 아래와 같습니다.
                </h2>
                <div class="graph_container">
                    <div class="total_percent">
                    <h3>종합 매칭 수준</h3>
                    <p><span id="provider_percent">0</span>%</p>
                    </div>
                    <canvas id="providerGraph" width="1000" height="1000"></canvas>
                </div>
            </div>

            <div class="graph">
                <h2>
                    <span>"㈜*** 회원(판매자)"</span> 과 매칭 수준은 아래와 같습니다.
                </h2>
                <div class="graph_container">
                    <div class="total_percent">
                    <h3>종합 매칭 수준</h3>
                    <p><span id="seller_percent">0</span>%</p>
                    </div>
                    <canvas id="sellerGraph" width="1000" height="1000"></canvas>
                </div>
            </div>
        </div>
    </div>
    <!--page04 끝-->

    <!--거래토큰 banner 시작-->
    <div class="banner_ticket" @click="openTicketPopup">
        <div class="inner">
            <img src="@/assets/images/main/illu_token_w.svg" alt="토큰" />
            <p>
                거래토큰 구매하고 성공 유망한 회원과 먼저 거래하세요. <span>>></span>
            </p>
        </div>
    </div>
    <TicketPopup
      :showTicketPopup="showTicketPopup"
      @close-ticketpopup="closeTicketPopup"
    />
    <!--거래토큰 banner 끝-->

    <!--page05 시작-->
    <div class="page05 page">
        <h1><span>"메타바이셀"</span>을 활용해야 하는 이유?</h1>
        <div class="inner">
            <div class="text">
                <img src="@/assets/images/common/logo_01.svg" alt="logo" />
                <h2>주요 서비스 장점 10계명</h2>
                <ul>
                    <li>회원 가입비가 없습니다.</li>
                    <li>거래수수료가 없습니다.</li>
                    <li>무료로 가장 적합한 국내, 해외 거래처를 찾아드립니다.</li>
                    <li>시장트렌드, 제품트렌드에 대한 신뢰도 높은 정보를 얻을 수 있습니다.</li>
                    <li>과학적 알고리즘으로 검증된 거래처를 구분해 드립니다.</li>
                    <li>상품, 거래처 소싱을 의뢰할 수 있습니다.</li>
                    <li>OEM 제작을 의뢰할 수 있습니다.</li>
                    <li>거래토큰 보유만으로 거래 상대방에게 노출빈도를 높일 수 있습니다.</li>
                    <li>자동견적, 입찰을 통해 가장 합리적인 가격으로 수익을 극대화합니다.</li>
                    <li>3분 안에 모든 프로세스가 가능합니다.</li>
                </ul>
            </div>
            <div class="image_wrap"></div>
        </div>
    </div>
    <!--page05 끝-->

    <!--page06 시작-->
    <div class="page06 page">
        <h1>
            다양한 기능과 혜택을 통해<br />
            공급자와 판매자 모두에게 수익의 극대화를 가져다 드립니다.
        </h1>
        <div class="inner">
            <div class="box_list">
                <div class="subtitle">
                    <p>판매자 혜택</p>
                    <!-- <img src="@/assets/images/main/icon_list_01.svg" alt="판매자"/> -->
                    <img src="@/assets/images/common/icon_done_w.svg" alt="done" />
                </div>
                <ul>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_01.svg" alt="icon_benefit_01" />
                            <img src="@/assets/images/main/icon_benefit_01_w.svg" alt="icon_benefit_01_w" />
                        </div>
                        국내 공급자 찾기
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_02.svg" alt="icon_benefit_02" />
                            <img src="@/assets/images/main/icon_benefit_02_w.svg" alt="icon_benefit_02_w" />
                        </div>
                        해외 공급자 찾기
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_03.svg" alt="icon_benefit_03" />
                            <img src="@/assets/images/main/icon_benefit_03_w.svg" alt="icon_benefit_03_w" />
                        </div>
                        제조사 찾기 → OEM 회의
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_04.svg" alt="icon_benefit_04" />
                            <img src="@/assets/images/main/icon_benefit_04_w.svg" alt="icon_benefit_04_w" />
                        </div>
                        거래처 조건별 검색
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_05_01.svg" alt="icon_benefit_05_01" />
                            <img
                            src="@/assets/images/main/icon_benefit_05_01_w.svg"
                            alt="icon_benefit_05_01_w"
                            />
                        </div>
                        상품 및 거래처 자동 매칭-추천
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_06.svg" alt="icon_benefit_06" />
                            <img src="@/assets/images/main/icon_benefit_06_w.svg" alt="icon_benefit_06_w" />
                        </div>
                        거래토큰으로 레벨상승, 노출확대
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_07.svg" alt="icon_benefit_07" />
                            <img src="@/assets/images/main/icon_benefit_07_w.svg" alt="icon_benefit_07_w" />
                        </div>
                        샘플구매
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_08.svg" alt="icon_benefit_08" />
                            <img src="@/assets/images/main/icon_benefit_08_w.svg" alt="icon_benefit_08_w" />
                        </div>
                        시장/제품 트렌드 자료열람
                    </li>
                </ul>
            </div>

            <div class="box_list">
                <div class="subtitle">
                    <p>공급자 혜택</p>
                    <!-- <img src="@/assets/images/main/icon_list_02.svg" alt="공급자"/> -->
                    <img src="@/assets/images/common/icon_done_w.svg" alt="done" />
                </div>
                <ul>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_01.svg" alt="icon_benefit_01" />
                            <img src="@/assets/images/main/icon_benefit_01_w.svg" alt="icon_benefit_01_w" />
                        </div>
                        국내 판매자 찾기
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_02.svg" alt="icon_benefit_02" />
                            <img src="@/assets/images/main/icon_benefit_02_w.svg" alt="icon_benefit_02_w" />
                        </div>
                        해외 판매자 찾기
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_03.svg" alt="icon_benefit_03" />
                            <img src="@/assets/images/main/icon_benefit_03_w.svg" alt="icon_benefit_03_w" />
                        </div>
                        제조사 찾기 → OEM 회의
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_04.svg" alt="icon_benefit_04" />
                            <img src="@/assets/images/main/icon_benefit_04_w.svg" alt="icon_benefit_04_w" />
                        </div>
                        거래처 조건별 검색
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_05_02.svg" alt="icon_benefit_05_02" />
                            <img
                            src="@/assets/images/main/icon_benefit_05_02_w.svg"
                            alt="icon_benefit_05_02_w"
                            />
                        </div>
                        공장 및 거래처 자동 매칭
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_06.svg" alt="icon_benefit_06" />
                            <img src="@/assets/images/main/icon_benefit_06_w.svg" alt="icon_benefit_06_w" />
                        </div>
                        거래토큰으로 레벨상승, 노출확대
                    </li>
                    <li>
                        <div class="icon_box">
                            <img src="@/assets/images/main/icon_benefit_08.svg" alt="icon_benefit_08" />
                            <img src="@/assets/images/main/icon_benefit_08_w.svg" alt="icon_benefit_08_w" />
                        </div>
                        시장/제품 트렌드 자료열람
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!--page06 끝-->

    <!--page07 시작-->
    <div class="page07 page">
        <h1>
            <div class="title">
                <img src="@/assets/images/main/icon_metago.svg" alt="meta_go" />
                <p>메타고 - "Meta Go"</p>
            </div>
            <p>새로운 공간, 새로운 방법으로</p>
            <p>가장 편하게 거래를 시작합니다.</p>
        </h1>
        <div class="explain_wrap">
            <div class="explain">
                <div class="explain_image">
                    <img src="@/assets/images/main/explain_01.jpg" alt="explain_01" />
                </div>
                <p>
                    고객님의 상품을 이제<br />우리의 공간에 올려 놓으세요.<br />
                    시너지가 높은 거래를 만들어 드립니다.
                </p>
            </div>

            <div class="explain">
                <div class="explain_image">
                    <img src="@/assets/images/main/explain_02.jpg" alt="explain_02" />
                </div>
                <p>
                    실 거래 전 가상거래 시뮬레이션을 통해<br />
                    당신의 거래 성공 가능성을 예측합니다.
                </p>
            </div>

            <div class="explain">
                <div class="explain_image">
                    <img src="@/assets/images/main/explain_03.jpg" alt="explain_03" />
                </div>
                <p>언제 어디서든,<br /> 당신의 거래는 원활하게 이루어지고 있습니다.</p>
            </div>
        </div>
    </div>
    <!--page07 끝-->

    <!--page08 시작-->
    <div class="page08 page">
        <h1>
            <p>매칭 등록 후, 24시간 이내</p>
            <p>최소 5개의 매칭 제안을 받아보세요 .</p>
        </h1>
        <div class="box_btn_link">
            <router-link to="/marketAnalyze/0" class="btn_link">
                <div class="icon_link">
                    <img src="@/assets/images/main/icon_graph_w.svg" alt="시장분석" />
                </div>
                <p>시장분석</p>
            </router-link>

            <router-link to="/request/sourcing" class="btn_link">
                <div class="icon_link">
                    <img src="@/assets/images/main/icon_tab_03_w.svg" alt="상품소싱" />
                </div>
                <p>상품소싱</p>
            </router-link>

            <router-link to="/request/consulting" class="btn_link">
                <div class="icon_link">
                    <img src="@/assets/images/main/icon_tab_04_w.svg" alt="컨설팅" />
                </div>
                <p>거래중개 컨설팅</p>
            </router-link>

            <a href="javascript:void(0)" @click="readyAlert" class="btn_link">
                <div class="icon_link">
                    <img src="@/assets/images/main/icon_youtube_w.svg" alt="유튜브" />
                </div>
                <p>유튜브</p>
            </a>
        </div>
    </div>
    <!--page08 끝-->

    <!--page09 시작-->
    <div class="page09 page">
        <h1>
            <p>당신이 원하는 거래</p>
            <p>지금 시작합니다.</p>
        </h1>
        <img src="@/assets/images/common/logo_01.svg" alt="로고" />
        <router-link to="/product">
            <button type="button" class="common_button">
                시작하기
            </button>
        </router-link>
    </div>
    <!--page09 끝-->
  </div>

  <ToTop />
  <Footer />
</template>

<script>
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([ Autoplay]);


import { mapState } from "vuex";

import { numberFormat } from "@/utils/numberFormat";
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { counting } from "@/utils/counting";

import Footer from "@/components/Footer";
// import HomeTabs from '@/components/Home/HomeTabs'
import TicketPopup from "@/components/TicketPopup";
import MainPopup from "@/components/MainPopup";
import ToTop from "@/components/ToTop";

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    Footer,
    // HomeTabs,
    TicketPopup,
    MainPopup,
    ToTop,
  },
  mixins: [numberFormat, fetchData, postData, counting],
  computed : {
    ...mapState('user', ['user']),
    ad_mid_provider(){
      return this.ad_mid.filter(el=>el.ad_division == 'P');
    },
    ad_mid_seller(){
      return this.ad_mid.filter(el=>el.ad_division == 'S');
    }
  },
  data() {
    return {
      showTicketPopup: false,

      whichCategory: 0,
      ad_top: [],
      ad_mid: [],
      category: [
        {
          title: "패션",
          consumer: { count: 0, total: 0 },
          provider: { count: 0, total: 0 },
          trade: 0,
        },
        {
          title: "뷰티",
          consumer: { count: 0, total: 0 },
          provider: { count: 0, total: 0 },
          trade: 0,
        },
        {
          title: "가구/인테리어",
          consumer: { count: 0, total: 0 },
          provider: { count: 0, total: 0 },
          trade: 0,
        },
        {
          title: "생활용품",
          consumer: { count: 0, total: 0 },
          provider: { count: 0, total: 0 },
          trade: 0,
        },
        {
          title: "생활가전",
          consumer: { count: 0, total: 0 },
          provider: { count: 0, total: 0 },
          trade: 0,
        },
        {
          title: "식료품",
          consumer: { count: 0, total: 0 },
          provider: { count: 0, total: 0 },
          trade: 0,
        },
      ],
      AI: {
        provider: {
          keyword1: [70, 15, 20, 72, 50, 75],
          keyword2: [40, 50, 50, 25, 75, 15],
          percent: 85,
        },
        seller: {
          keyword1: [80, 60, 50, 25, 60, 50],
          keyword2: [26, 80, 65, 80, 50, 25],
          percent: 92,
        },
      },

      // page03 왔었는지? (counting 효과 때문ㅠ)
      isPage03Scrolled: false,
      // page04 왔었는지? (counting 효과 때문ㅠ)
      isPage04Scrolled: false,

      isFetched: false,

      swiperOption: {
        // speed: 600,
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 2200,
          disableOnInteraction: false,
        },
      },
      swiperOptionDefault: {
        allowTouchMove: false,
      }
    };
  },
  created(){
    this.socialLogin();
  },
  mounted() {
    this.$nextTick(() => { 
      this.fetchBasicData();
      // this.page01Motion();
      this.page04Graph();
      window.addEventListener("scroll", this.mainMotion);
    });
  },
  unmounted() {
    window.removeEventListener("scroll", this.mainMotion);
  },
  watch: {
    whichCategory() {
      this.categoryCounting();
    },
  },
  methods: {
    socialLogin(){
      // console.log(this.$route.query);
      if(this.$route.query.t){
        this.postData(
          "/tSignin",
          { t : this.$route.query.t },
          (data) => {
            this.$store.commit("user/signin", data.user);
          }
        );
      }
    },
    fetchBasicData() {
      this.fetchData("/getBasic?page=home", (data) => {
        this.category = data.category;
        this.ad_top = data.ad_top;
        this.ad_mid = data.ad_mid;
        this.isFetched = true;
        this.mainMotion();
      });
    },
    readyAlert() {
      this.$store.commit("alert", "준비중 입니다.");
    },
    // page03의 카테고리 체크시 동작하는 함수
    categoryCheck(event, num) {
      const categories = document.querySelectorAll(
        ".page03 .box_category .category"
      );
      categories.forEach((el) => el.classList.remove("on"));
      event.currentTarget.classList.add("on");

      this.whichCategory = num;
    },
    // page03 카운팅효과
    categoryCounting() {
      const consumerCount = document.getElementById("consumer-count");
      const providerCount = document.getElementById("provider-count");
      const tradeCount = document.getElementById("trade-count");

      this.counting(
        consumerCount,
        this.category[this.whichCategory].consumer.count
      );
      this.counting(
        providerCount,
        this.category[this.whichCategory].provider.count
      );
      this.counting(tradeCount, this.category[this.whichCategory].trade);
    },

    // ticketPopup 보이기
    openTicketPopup() {
      this.showTicketPopup = true;
    },
    closeTicketPopup() {
      this.showTicketPopup = false;
    },

    // page04 AI 거래매칭 그래프 배경
    page04Graph() {
      const canvasProvider = document.getElementById("providerGraph");
      const canvasSeller = document.getElementById("sellerGraph");
      const ctx1 = canvasProvider.getContext("2d");
      const ctx2 = canvasSeller.getContext("2d");

      const start = {
        keyword1: [0, 0, 0, 0, 0, 0],
        keyword2: [0, 0, 0, 0, 0, 0],
      };
      this.drawGraph(ctx1, start, "graph1");
      this.drawGraph(ctx2, start, "graph2");
    },
    // page04 AI 거래매칭 그래프 애니메이션
    page04GraphAnimate() {
      const canvasProvider = document.getElementById("providerGraph");
      const canvasSeller = document.getElementById("sellerGraph");
      const ctx1 = canvasProvider.getContext("2d");
      const ctx2 = canvasSeller.getContext("2d");

      const current1 = {
        keyword1: [0, 0, 0, 0, 0, 0],
        keyword2: [0, 0, 0, 0, 0, 0],
      };
      const current2 = {
        keyword1: [0, 0, 0, 0, 0, 0],
        keyword2: [0, 0, 0, 0, 0, 0],
      };
      const graph1 = setInterval(() => {
        ctx1.clearRect(0, 0, 1000, 1000);
        this.drawGraph(ctx1, current1, "graph1");

        if (
          this.AI.provider.keyword1.every(
            (el, i) => el == current1.keyword1[i]
          ) &&
          this.AI.provider.keyword2.every((el, i) => el == current1.keyword2[i])
        ) {
          clearInterval(graph1);
        } else {
          current1.keyword1 = current1.keyword1.map((el, i) =>
            el == this.AI.provider.keyword1[i] ? el : el + 1
          );
          current1.keyword2 = current1.keyword2.map((el, i) =>
            el == this.AI.provider.keyword2[i] ? el : el + 1
          );
        }
      }, 8);
      const graph2 = setInterval(() => {
        ctx2.clearRect(0, 0, 1000, 1000);
        this.drawGraph(ctx2, current2), "graph2";

        if (
          this.AI.seller.keyword1.every(
            (el, i) => el == current2.keyword1[i]
          ) &&
          this.AI.seller.keyword2.every((el, i) => el == current2.keyword2[i])
        ) {
          clearInterval(graph2);
        } else {
          current2.keyword1 = current2.keyword1.map((el, i) =>
            el == this.AI.seller.keyword1[i] ? el : el + 1
          );
          current2.keyword2 = current2.keyword2.map((el, i) =>
            el == this.AI.seller.keyword2[i] ? el : el + 1
          );
        }
      }, 8);
    },
    // 그래프 그리는 함수
    drawGraph(ctx, data, which) {
      drawBackground(ctx, which);
      drawData(ctx, 400, data);
      drawCircles(ctx, 400, 40);

      // 입력 데이터 그리는 함수
      function drawData(ctx, r, data) {
        ctx.beginPath();
        for (let i = 0; i < 6; i++) {
          const dataPercent = data.keyword2[i] / 100;
          ctx.lineTo(
            500 +
              r *
                Math.cos((Math.PI / 3) * i - Math.PI / 2) *
                (4 / 7) *
                dataPercent +
              r * Math.cos((Math.PI / 3) * i - Math.PI / 2) * (3 / 7),
            500 +
              r *
                Math.sin((Math.PI / 3) * i - Math.PI / 2) *
                (4 / 7) *
                dataPercent +
              r * Math.sin((Math.PI / 3) * i - Math.PI / 2) * (3 / 7)
          );
        }
        ctx.closePath();
        ctx.strokeStyle = "#87BEBC";
        ctx.fillStyle = "rgba(135,190,188, 0.2)";
        ctx.stroke();
        ctx.fill();

        ctx.beginPath();
        for (let i = 0; i < 6; i++) {
          const dataPercent = data.keyword1[i] / 100;
          ctx.lineTo(
            500 +
              r *
                Math.cos((Math.PI / 3) * i - Math.PI / 2) *
                (4 / 7) *
                dataPercent +
              r * Math.cos((Math.PI / 3) * i - Math.PI / 2) * (3 / 7),
            500 +
              r *
                Math.sin((Math.PI / 3) * i - Math.PI / 2) *
                (4 / 7) *
                dataPercent +
              r * Math.sin((Math.PI / 3) * i - Math.PI / 2) * (3 / 7)
          );
        }
        ctx.closePath();
        ctx.strokeStyle = "#222";
        ctx.stroke();
      }

      // 그래프 배경 그리는 함수
      function drawBackground(ctx, which) {
        const r = 400;
        drawHexagon(ctx, r);
        drawHexagon(ctx, r * (6 / 7));
        drawHexagon(ctx, r * (5 / 7));
        drawHexagon(ctx, r * (4 / 7));
        drawHexagon(ctx, r * (3 / 7));

        drawTags(ctx, r + 40, which);
      }

      // 육각형 그리기
      function drawHexagon(ctx, r) {
        ctx.beginPath();
        for (let i = 0; i < 6; i++) {
          ctx.lineTo(
            500 + r * Math.cos((Math.PI / 3) * i - Math.PI / 2),
            500 + r * Math.sin((Math.PI / 3) * i - Math.PI / 2)
          );
        }
        ctx.closePath();
        ctx.strokeStyle = "#dedede";
        ctx.stroke();
      }
      // 각 지표 동그라미 그리기
      function drawCircles(ctx, r, size) {
        for (let i = 0; i < 6; i++) {
          ctx.beginPath();
          ctx.arc(
            500 + r * Math.cos((Math.PI / 3) * i - Math.PI / 2),
            500 + r * Math.sin((Math.PI / 3) * i - Math.PI / 2),
            size,
            0,
            Math.PI * 2
          );
          ctx.closePath();
          ctx.fillStyle = "#b0b0b0";
          ctx.fill();

          ctx.beginPath();
          ctx.fillStyle = "#fff";
          ctx.font = "40px san-serif";
          ctx.fillText(
            `${i + 1}`,
            500 + r * Math.cos((Math.PI / 3) * i - Math.PI / 2) - size / 2 + 9,
            500 + r * Math.sin((Math.PI / 3) * i - Math.PI / 2) + size / 2 - 6
          );
          ctx.fill();
          ctx.closePath();
        }
      }
      // 각 태그 그리기
      function drawTags(ctx, r, which) {
        ctx.fillStyle = "#000";
        ctx.font = "32px san-serif";
        ctx.beginPath();
        if (which == "graph1") {
          ctx.fillText(
            "카테고리",
            500 + r * Math.cos((Math.PI / 3) * 0 - Math.PI / 2) - 60,
            500 + r * Math.sin((Math.PI / 3) * 0 - Math.PI / 2) - 15
          );
          ctx.fillText(
            "공급경력",
            500 + r * Math.cos((Math.PI / 3) * 1 - Math.PI / 2) - 98,
            500 + r * Math.sin((Math.PI / 3) * 1 - Math.PI / 2) - 33
          );
          ctx.fillText(
            "공급가",
            500 + r * Math.cos((Math.PI / 3) * 2 - Math.PI / 2) - 82,
            500 + r * Math.sin((Math.PI / 3) * 2 - Math.PI / 2) + 60
          );
          ctx.fillText(
            "예상제조기간",
            500 + r * Math.cos((Math.PI / 3) * 3 - Math.PI / 2) - 95,
            500 + r * Math.sin((Math.PI / 3) * 3 - Math.PI / 2) + 40
          );
          ctx.fillText(
            "예상마진",
            500 + r * Math.cos((Math.PI / 3) * 4 - Math.PI / 2) - 30,
            500 + r * Math.sin((Math.PI / 3) * 4 - Math.PI / 2) + 60
          );
          ctx.fillText(
            "단가",
            500 + r * Math.cos((Math.PI / 3) * 5 - Math.PI / 2) + 3,
            500 + r * Math.sin((Math.PI / 3) * 5 - Math.PI / 2) - 33
          );
        } else {
          ctx.fillText(
            "카테고리",
            500 + r * Math.cos((Math.PI / 3) * 0 - Math.PI / 2) - 60,
            500 + r * Math.sin((Math.PI / 3) * 0 - Math.PI / 2) - 15
          );
          ctx.fillText(
            "판매경력",
            500 + r * Math.cos((Math.PI / 3) * 1 - Math.PI / 2) - 98,
            500 + r * Math.sin((Math.PI / 3) * 1 - Math.PI / 2) - 33
          );
          ctx.fillText(
            "준비예산",
            500 + r * Math.cos((Math.PI / 3) * 2 - Math.PI / 2) - 95,
            500 + r * Math.sin((Math.PI / 3) * 2 - Math.PI / 2) + 60
          );
          ctx.fillText(
            "판매가능시점",
            500 + r * Math.cos((Math.PI / 3) * 3 - Math.PI / 2) - 95,
            500 + r * Math.sin((Math.PI / 3) * 3 - Math.PI / 2) + 40
          );
          ctx.fillText(
            "예상마진",
            500 + r * Math.cos((Math.PI / 3) * 4 - Math.PI / 2) - 30,
            500 + r * Math.sin((Math.PI / 3) * 4 - Math.PI / 2) + 60
          );
          ctx.fillText(
            "희망단가",
            500 + r * Math.cos((Math.PI / 3) * 5 - Math.PI / 2) - 27,
            500 + r * Math.sin((Math.PI / 3) * 5 - Math.PI / 2) - 33
          );
        }

        ctx.fill();
        ctx.closePath();
      }
    },

    // 스크롤 할 때 각페이지들 등장 모션
    mainMotion() {
    // const page01 = document.querySelector('.page01');
    //   const page02 = document.querySelector(".page02");
      const page03 = document.querySelector(".page03");
      const page04 = document.querySelector(".page04");
      const page05 = document.querySelector(".page05");
      const page06 = document.querySelector(".page06");
      const page07 = document.querySelector(".page07");
      const page08 = document.querySelector(".page08");
      const page09 = document.querySelector(".page09");

    //   if (window.scrollY > page02.offsetTop - (window.innerHeight * 2) / 3) {
    //     this.page02Motion();
    //   }
      if (
        window.scrollY >
        page03.offsetTop - 100 - (window.innerHeight * 1) / 3
      ) {
        this.page03Motion1();
      }
      if (window.scrollY > page03.offsetTop + (window.innerHeight * 1) / 4) {
        this.page03Motion2();
      }
      if (
        window.scrollY >
        page04.offsetTop - 150 - (window.innerHeight * 1) / 4
      ) {
        this.page04Motion();
      }
      if (
        window.scrollY >
        page05.offsetTop - 150 - (window.innerHeight * 2) / 3
      ) {
        this.page05Motion1();
      }
      if (
        window.scrollY >
        page05.offsetTop - 150 - (window.innerHeight * 1) / 5
      ) {
        this.page05Motion2();
      }
      if (window.scrollY > page06.offsetTop - (window.innerHeight * 2) / 3) {
        this.page06Motion();
      }
      if (window.scrollY > page07.offsetTop - (window.innerHeight * 2) / 3) {
        this.page07Motion();
      }
      if (
        window.scrollY >
        page08.offsetTop - 150 - (window.innerHeight * 2) / 5
      ) {
        this.page08Motion();
      }
      if (
        window.scrollY >
        page09.offsetTop - 150 - (window.innerHeight * 2) / 3
      ) {
        this.page09Motion();
      }
    },
    // page01Motion() {
    //   const heading = document.querySelector(".main .page01 .inner .text h1");
    //   const paragraph = document.querySelector(".main .page01 .inner .text p");
    //   const image = document.querySelector(".main .page01 .inner .slide");
    //   const button = document.querySelector(
    //     ".main .page01 .inner .text .box_button"
    //   );

    //   const delay = 200;
    //   setTimeout(() => {
    //     image.style.opacity = 1;
    //   }, delay * 0.8);
    //   setTimeout(() => {
    //     heading.style.opacity = 1;
    //   }, delay * 1.8);
    //   setTimeout(() => {
    //     paragraph.style.opacity = 1;
    //   }, delay * 2.5);
    //   setTimeout(() => {
    //     button.style.opacity = 1;
    //   }, delay * 3);
    // },
    page02Motion() {
      const banner = document.querySelectorAll(
        ".main .page02 .box_banner .banner"
      );
      banner.forEach((el, i) => {
        setTimeout(() => {
          el.style.opacity = 1;
          el.style.transform = "translateY(0)";
        }, i * 200);
      });
    },
    page03Motion1() {
      const categories = document.querySelectorAll(
        ".main .page03 .box_category .category"
      );
      categories.forEach((el, i) => {
        setTimeout(() => {
          el.style.transform = "translateY(0)";
        }, i * 90);
      });
    },
    page03Motion2() {
      // 데이터 받기 전에 모션이 동작해버리면 0에다 countup을 해버려서 화면에 값이 0으로 표시된다. 그래서 isFetched 추가함
      if (this.isFetched && !this.isPage03Scrolled) {
        const page03_2 = document.querySelectorAll(
          ".main .page03 .page03_2 .box_scale .scale"
        );
        page03_2.forEach((el) => {
          el.style.transform = "scale(1)";
        });

        this.categoryCounting();
        this.isPage03Scrolled = true;
      }
    },
    page04Motion() {
      if (!this.isPage04Scrolled) {
        const provider_percent = document.getElementById("provider_percent");
        const seller_percent = document.getElementById("seller_percent");
        this.counting(provider_percent, this.AI.provider.percent);
        this.counting(seller_percent, this.AI.seller.percent);

        this.page04GraphAnimate();
      }
      this.isPage04Scrolled = true;
    },
    page05Motion1() {
      const heading = document.querySelector(".main .page05 h1");
      const logo = document.querySelector(".main .page05 .inner .text img");
      const heading2 = document.querySelector(".main .page05 .inner .text h2");
      const image = document.querySelector(".main .page05 .inner .image_wrap");

      heading.style.opacity = 1;
      heading.style.transform = "translateY(0)";
      logo.style.opacity = 1;
      logo.style.transform = "translateY(0)";
      heading2.style.opacity = 1;
      heading2.style.transform = "translateY(0)";
      image.style.opacity = 1;
      image.style.transform = "translateY(0)";
    },
    page05Motion2() {
      const list = document.querySelectorAll(
        ".main .page05 .inner .text ul li"
      );
      list.forEach((el, i) => {
        setTimeout(() => {
          el.style.transform = "rotateX(0deg)";
          el.style.opacity = 1;
        }, 120 * i);
      });
    },
    page06Motion() {
      const heading = document.querySelector(".main .page06 h1");
      const boxes = document.querySelectorAll(".main .page06 .inner .box_list");

      heading.style.transform = "translateY(0)";
      heading.style.opacity = 1;
      boxes.forEach((el, i) => {
        setTimeout(() => {
          el.style.opacity = 1;
          el.style.transform = "translateY(0)";
        }, 300 * i + 500);
      });
    },
    page07Motion() {
      const heading = document.querySelector(".main .page07 h1");
      const imageBoxes = document.querySelectorAll(
        ".main .page07 .explain_wrap .explain"
      );

      heading.style.transform = "translateY(0)";
      heading.style.opacity = 1;
      imageBoxes.forEach((el, i) => {
        setTimeout(() => {
          el.style.opacity = 1;
          el.style.transform = "translateY(0)";
        }, 300 * i + 100);
      });
    },
    page08Motion() {
      const headeings = document.querySelectorAll(".main .page08 h1 p");
      const links = document.querySelectorAll(
        ".main .page08 .box_btn_link .btn_link"
      );

      headeings.forEach((el) => {
        el.style.transform = "translateX(0)";
        el.style.opacity = 1;
      });
      links.forEach((el, i) => {
        setTimeout(() => {
          el.style.opacity = 1;
        }, 200 * i);
      });
    },
    page09Motion() {
      const paragraph = document.querySelectorAll(".main .page09 h1 p");
      const logo = document.querySelector(".main .page09 img");
      const start = document.querySelector(".main .page09 a");

      paragraph.forEach((el, i) => {
        setTimeout(() => {
          el.style.opacity = 1;
          el.style.transform = "translateY(0)";
        }, 900 * i);
      });
      setTimeout(() => {
        logo.style.opacity = 1;
      }, 1800);
      setTimeout(() => {
        start.style.visibility = "visible";
        start.style.opacity = 1;
      }, 2300);
    },
  },
};
</script>

<style src="@/assets/css/main.css"></style>
